





























































































import {Component, Prop, VModel, Vue} from 'vue-property-decorator';
import Company from '@/models/Company';
import {CountryFlags} from '@/misc/CountryStorage';
import {namespace} from 'vuex-class';
import {Permission} from '@/misc/enums/permission.enum';

const CompanyStore = namespace('company');

/**
 * Simple component that lists all companies in a table
 */
@Component({
  components: {
    TableComponent: () => import(
        '@/components/shared/table/Table.component.vue'),
  },
})
export default class CompanyTableComponent extends Vue {

  @VModel({default: '', type: String})
  public searchString!: string;
  @Prop({default: []})
  private companies!: Company[];

  @CompanyStore.Mutation('activeCompany')
  private activeCompany!: (company: Company) => void;

  @CompanyStore.Action('editCompanyAction')
  private editCompany!: (company: Company) => Promise<Company>;

  private countryFlags: any = {};
  private showChangeStatusDialog: boolean = false;
  private selectedCompany: Company = new Company();

  constructor() {
    super();
  }

  private mounted() {
    this.countryFlags = CountryFlags;
  }

  private get importantKeys() {
    return {name: String, address: {street: String, city: String}};
  }

  private get tableHeaders(): any {
    return [
      {text: this.$t('COMPANIES_OVERVIEW.TABLE.COMPANY'), value: 'name', width: '20%', class: 'd-flex justify-start align-center'},
      {
        text: this.$t('COMPANIES_OVERVIEW.TABLE.ADDRESS'),
        value: 'address',
        width: '20%',
        class: 'd-flex justify-start align-center',
      },
      {text: this.$t('COMPANIES_OVERVIEW.TABLE.ZIP'), value: 'postalCode', width: '10%'},
      {text: this.$t('COMPANIES_OVERVIEW.TABLE.CITY'), value: 'city', width: '10%'},
      {text: this.$t('COMPANIES_OVERVIEW.TABLE.COUNTRY'), value: 'country'},
      {text: this.$t('COMPANIES_OVERVIEW.TABLE.EMPLOYEES'), value: 'users', sortable: false},
      {text: this.$t('GENERAL.STATUS'), value: 'active'},
    ];
  }

  private async onCompanyChangeStatus(updatedCompany: Company) {
    this.showChangeStatusDialog = false;

    try {

      updatedCompany.active = !updatedCompany.active;
      updatedCompany = (await this.editCompany(updatedCompany));

      if (updatedCompany.active) {
        this.$notifySuccessSimplified('COMPANY_MANAGE.NOTIFICATIONS.COMPANY_ACTIVATED');
      } else {
        this.$notifySuccessSimplified('COMPANY_MANAGE.NOTIFICATIONS.COMPANY_DEACTIVATED');
      }
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }

  private onRowClicked(item: Company) {
    this.activeCompany(item);
    this.$router.push({name: 'companyDashboard', params: {companyId: item.id}});
  }

  private getCompanyColor(company: Company, isText: boolean) {
    return company.getColorForUserState(isText);
  }

  public onCompanyStatusToggleClick(company: Company) {
    if (this.$userRoleHandler.hasPermission(Permission.COMPANY_UPDATE_OWN)) {
      this.showChangeStatusDialog = true;
      this.selectedCompany = company;
    }
  }

  private getColor(color: string): string {
    return this.$colorHandler.getThemeColor(color);
  }

  private get isActive() {
    const inactive: string = (this.$t('GENERAL.INACTIVE') as string).toLowerCase();
    const active: string = (this.$t('GENERAL.ACTIVE') as string).toLowerCase();

    return {
      opposite: this.selectedCompany.active ? inactive : active,
    };
  }

}
